import React, { useCallback, useState } from "react";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import mapStyle from "../../data/map.json";

const PinMap = ({ onPinLocation }) => {
    const [selectedPosition, setSelectedPosition] = useState(null);

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY,
        libraries: ["drawing"]
    });

    // Function to animate marker movement
    const animateMarker = (startPos, endPos) => {
        if (!startPos) {
            setSelectedPosition(endPos); // First-time pin, no animation needed
            return;
        }

        const frames = 30; // Faster animation (30 frames)
        let frame = 0;

        const latStep = (endPos.lat - startPos.lat) / frames;
        const lngStep = (endPos.lng - startPos.lng) / frames;

        const interval = setInterval(() => {
            frame++;
            const newLat = startPos.lat + latStep * frame;
            const newLng = startPos.lng + lngStep * frame;

            setSelectedPosition({ lat: newLat, lng: newLng });

            if (frame >= frames) {
                clearInterval(interval); // Stop animation
                setSelectedPosition(endPos); // Ensure final position is precise
            }
        }, 10); // Faster interval for smoother animation
    };

    const handleMapClick = useCallback((event) => {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        const newPosition = { lat, lng };

        animateMarker(selectedPosition, newPosition);

        if (onPinLocation) {
            onPinLocation(newPosition);
        }
    }, [onPinLocation, selectedPosition]);

    return (
        <>
            {!isLoaded ? (
                <h6 style={{ marginTop: 40 }}>Loading Map...</h6>
            ) : (
                <div>
                    <GoogleMap
                        mapContainerClassName="map-container"
                        center={selectedPosition || { lat: 0, lng: 0 }}
                        zoom={selectedPosition ? 15 : 2}
                        options={{ styles: mapStyle }}
                        onClick={handleMapClick}
                    >
                        {selectedPosition && (
                            <Marker position={selectedPosition} />
                        )}
                    </GoogleMap>
                </div>
            )}
        </>
    );
};

export default PinMap;
